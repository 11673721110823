@font-face {
  font-family: "Iconify Explorer Font";
  src: url("./iconfont.eot");
  src: url("./iconfont.eot") format("embedded-opentype"),
    url("./iconfont.ttf") format("truetype"),
    url("./iconfont.woff") format("woff"),
    url("./iconfont.woff2") format("woff2"), url("./iconfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.i {
  font-family: "Iconify Explorer Font" !important;
  font-size: 1em;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i.comment:before {
  content: "\e001";
}

.i.edit:before {
  content: "\e002";
}

.i.back:before {
  content: "\e003";
}

.i.image:before {
  content: "\e004";
}

.i.trash:before {
  content: "\e005";
}

.i.options:before {
  content: "\e006";
}

.i.like:before {
  content: "\e007";
}
